'use client'

import Script from "next/script"

const apiKey = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ4M0FCMDhFNUYwRDMxNjdEOTRFMTQ3M0FEQTk2RTcyRDkwRUYwRkYiLCJ0eXAiOiJKV1QifQ.eyJqdGkiOiJkNjE4NDM3YS04OTVlLTRhNjgtYTgzNi04NjVkYTVmNjQ5NTQiLCJzdWIiOiIyNzIzMTMyMTYzNDc5NzE0Iiwic2NvcGUiOiJEYXRhLkV4cG9ydCIsIm5iZiI6MTc0MjkzMDAxNCwiZXhwIjo0ODk2NTMwMDE0LCJpYXQiOjE3NDI5MzAwMTQsImlzcyI6ImNsYXJpdHkiLCJhdWQiOiJjbGFyaXR5LmRhdGEtZXhwb3J0ZXIifQ.hXzzf9trarbimvESrOUDnPDrENvAdh_4h3U3aifn8n8PtBvNxuKUY4JypWjKSJkLcWHNJXIfL2iP8hTQXeZRvmU491LHoIS-lFp3ObRQjDGIlxtlwNNF3sva8Vf2RG2-p97a7796ViBp3EAGDlme2uLowlqLx7bJ-qqgxsduHE6a6D4VLUpdR3HZySbDVhFVXhIKJAJiTc5z6XNFyxv2FybMCGYc3TPVo8B_mm4tEXc7d7HuUIY-ANz2REmj_PnqycpexHflE9Gb2Pc8oS80Z756eJW2J8YKoWnbFq6v5ZjyK5qxiNm0LMt5UoBgHVXjw8z5i3p_xW30cXrIGed3YQ';
const microsoftClarityProject = 'qt9pn8y36q';

const MicrosoftClarity = () => {
  return (
    <Script
      id="microsoft-clarity-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${microsoftClarityProject}");
          `,
      }}
    />
  )
}

export {
  MicrosoftClarity
}
