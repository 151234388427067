import { glProps } from '@/shared/gl.props';

/**
 * Provider
 * */
import { LocalAnalyticsProvider } from '@/providers/analytics/providers/local-analytics.provider';
import { AmplitudeAnalyticsProvider } from '@/providers/analytics/providers/amplitude-analytics.provider';
import { GoogleAnalytics } from '@/providers/analytics/providers/google-analytics';
import { MicrosoftClarity } from '@/providers/analytics/providers/microsoft-clarity';

export const AnalyticsProvider = ({ children }: glProps.ComponentWithChildrenProps) => {
  const isProd = process.env.APP_ENV === 'production'

  return (
    <>
      {isProd && (
        <>
          <GoogleAnalytics />
          <MicrosoftClarity />
        </>
      )}

      {isProd ? (
        <AmplitudeAnalyticsProvider apiKey={process.env.AMPLITUDE_KEY || ''}>
          {children}
        </AmplitudeAnalyticsProvider>
      ) : (
        <LocalAnalyticsProvider>{children}</LocalAnalyticsProvider>
      )}
    </>
  )
}
